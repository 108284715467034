var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "steps" }, [
    _c(
      "div",
      { staticClass: "steps__items" },
      _vm._l(_vm.length, function (i) {
        return _c("div", {
          key: i,
          staticClass: "steps__items__item",
          class: { current: i === _vm.current, completed: i < _vm.current },
        })
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }