<script>
export default {
  props: {
    current: Number,
    length: Number,
  },
};
</script>

<template>
  <div class="steps">
    <div class="steps__items">
      <div
        v-for="i in length"
        :class="{ 'current': i === current, 'completed': i < current }"
        :key="i"
        class="steps__items__item"/>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.steps {
  /* background-color: var(--color-blue-medium); */
  background-color: var(--color-blue-onlypay);
  padding: 0 1.5rem;
  position: absolute -.0625rem 0 auto 0;
  @media (max-width: 576px) {
    margin-top: -1rem;
  }
  @media (min-width: 576px) {
    left: 24.75rem;
    padding: 0;
    right: .875rem;
    top: -3.5rem;
  }
  @media (min-width: 992px) {
    background-color: var(--color-white);
    left: 24.3125rem;
    padding: 0;
    right: -.25rem;
    top: -4rem;
  }
  &__items {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 .5rem;
    @media (min-width: 576px) {
      padding: 0;
    }
    &__item {
      background-color: var(--color-white);
      display: flex;
      flex-grow: 1;
      height: .125rem;
      position: relative;
      text-align: right;
      @media (min-width: 992px) {
        background-color: var(--color-grey-light);
      }
      &:before {
        content: '';
        /* background-color: var(--color-blue-medium); */
        background-color: var(--color-blue-onlypay);
        border: .0625rem solid var(--color-white);
        border-radius: 100%;
        position: absolute -.375rem auto auto -.5rem;
        size: .75rem;
        @media (min-width: 992px) {
          background-color: var(--color-white);
          /* border: .0625rem solid var(--color-blue-medium); */
          border: .0625rem solid var(--color-blue-onlypay);
        }
      }
      &:last-child {
        flex: 0 1 0;
      }
      &.current:before {
        background-color: var(--color-white);
        @media (min-width: 992px) {
          background-color: var(--color-blue-dark);
          border: none;
        }
      }
      &.completed:before {
        background-color: var(--color-green);
        border: none;
      }
    }
  }
}
</style>
