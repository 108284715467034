<script>
import Swal from 'sweetalert2';
import Moment from 'moment';
import VTextbox from '@/components/Textbox.vue';
import { getUserData } from '@/helpers/user';
import VCards from '../components/Cards.vue';
import VSteps from '../components/Steps.vue';
import VExtractTable from '../components/ExtractTable.vue';

export default {
  components: {
    VExtractTable,
    VCards,
    VSteps,
    VTextbox
  },
  data() {
    return {
      idContaCartaoExtrato: null,
      showError: false,
      date: 7,
      user: {},
      startDate: '',
      initialDate: 0,
      endDate: 0,
      finalDate: 0,
      extract: []
    }
  },
  methods: {
    showAlert() {
      Swal.fire({
        title: 'OnlyPay Informa',
        html: '<p style="text-align: left; font-size: 16px; margin-top:50px;margin-bottom:50px;">Estamos com instabilidade em nosso sistema.<br>Nosso time está trabalhando para resolução o mais breve possível. <br>Pedimos desculpas pelo transtorno. <br><br>Pedimos que direcione sua solicitações e dúvidas para faleconosco@onlypay.com.br.<p>',
        type: 'info',
        confirmButtonText: 'Ok'
      })
    },
    checkDate(value) {
      if (value) {
        this.initialDate = 0;
        this.finalDate = 0;
      }
    },
    nextStep(card) {
      if ((!this.startDate || !this.endDate) && !this.date) {
        Swal.fire({
          title: 'OnlyPay Informa',
          html: '<p style="text-align: left; font-size: 16px; margin-top:50px;margin-bottom:50px;">Selecione uma data válida.<p>',
          type: 'info',
          confirmButtonText: 'Ok'
        });
        return false;
      }
      let endDate = 0;
      let startDate = 0;

      if (this.initialDate === 0) {
        startDate = Moment(new Date(), 'YYYY-MM-DD').subtract(this.date, 'days').format('YYYY-MM-DD');
      } else if (this.initialDate !== 0) {
        startDate = this.initialDate;
      }

      if (this.finalDate === 0) {
        endDate = Moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD');
      } else if (this.finalDate !== 0) {
        endDate = this.finalDate;
      }
      // if (this.initialDate === 0 && this.finalDate === 0) {
      //   endDate = Moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD');
      //   startDate = Moment(new Date(), 'YYYY-MM-DD').subtract(this.date, 'days').format('YYYY-MM-DD');
      // } else if (this.initialDate !== 0 || this.finalDate !== 0) {
      //   endDate = this.finalDate;
      //   startDate = this.initialDate;
      // }
      this.idContaCartaoExtrato = card.idConta.toString();
      this.getExtract(startDate, endDate);
      return true
    },
    isPjUser() {
      return this.user.cnpj && !this.user.cpf
    },
    getExtract(startDate, endDate) {
      this.$store.commit('pageLoading', true);
      this.$http.get(`/cards/extract/${this.idContaCartaoExtrato}?dataFim=${endDate}&dataInicio=${startDate}`)
        .then((res) => {
          this.extract = res.data.content;

          for (let i = 0; i < this.extract.length; i += 1) {
            this.extract[i].valorBRL = this.extract[i].valorBRL.toLocaleString('en-US', { minimumFractionDigits: 2 }).split('.');
            // this.extract.splice(i, 1);
          }
          console.log(this.extract);
        })
        .catch(() => {
          this.idContaCartaoExtrato = null;
          Swal.fire({
            title: 'OnlyPay Informa',
            html: '<p style="text-align: left; font-size: 16px; margin-top:50px;margin-bottom:50px;">Não há extrato disponível para o período selecionado.<p>',
            type: 'info',
            confirmButtonText: 'Ok'
          })
        })
        .then(() => this.$store.commit('pageLoading', false));
    }
  },
  beforeMount() {
    this.user = getUserData();
    if (!this.isPjUser()) {
      this.$router.push({
        name: 'expenses'
      });
    }
  }
};
</script>

<template>
  <div class="expenses">
    <v-steps :current="idContaCartaoExtrato ? 2 : 1" :length="2" />
    <div v-if="!idContaCartaoExtrato">
      <p>Selecione o período e o cartão.</p>
      <br>
      <div class="row px-1 mb-4">
        <label class="container col-3">7 Dias
          <input type="radio" value="7" v-model="date" @click="checkDate('')">
          <span class="checkmark ml-2"></span>
        </label>
        <label class="container col-3">30 Dias
          <input type="radio" value="30" v-model="date" @click="checkDate('30')">
          <span class="checkmark ml-2"></span>
        </label>
        <label class="container col-3">60 Dias
          <input type="radio" value="60" v-model="date" @click="checkDate('60')">
          <span class="checkmark ml-2"></span>
        </label>
        <label class="container col-3">90 Dias
          <input type="radio" value="90" v-model="date" @click="checkDate('90')">
          <span class="checkmark ml-2"></span>
        </label>
        <!-- <label class="container col-3">Outro
          <input type="radio" value="outro" v-model="date" @click="checkDate('outro')">
          <span class="checkmark ml-2"></span>
        </label> -->
        <label  class="d-none d-sm-flex flex-column col-6"> Data início:
          <input class="px-2" style="border: 1px solid gray; border-radius: 4px;" type="date" v-model="initialDate"
            required />
        </label>
        <label class="d-none d-sm-flex flex-column col-6"> Data fim:
          <input class="px-2" style="border: 1px solid gray; border-radius: 4px;" type="date" v-model="finalDate"
            required />
        </label>
        <!-- Display in mobile -->
        <label class="d-flex d-sm-none flex-column col-6"> Data início:
          <input class="px-2" style="border: 1px solid gray; border-radius: 4px;" type="date" v-model="initialDate"
            required />
        </label>
        <label class="d-flex d-sm-none flex-column col-6"> Data fim:
          <input class="px-2" style="border: 1px solid gray; border-radius: 4px;" type="date" v-model="finalDate"
            required />
        </label>
        <!-- Display inmobile -->
      </div>
      <div class="row" v-if="date == 'outro'">
        <v-textbox
          v-model="startDate"
          data-vv-as="Data início"
          data-vv-scope="account"
          class="col-md-6"
          label="Data início"
          mask="##/##/####"
          placeholder="dd/mm/aaaa"
          type="tel"
          validate="required|date_format:DD/MM/YYYY" required/>
        <v-textbox
          v-model="endDate"
          data-vv-as="Data fim"
          class="col-md-6"
          data-vv-scope="account"
          label="Data fim"
          mask="##/##/####"
          placeholder="dd/mm/aaaa"
          type="tel"
          validate="required|date_format:DD/MM/YYYY" required/>
      </div>
      <v-cards v-on:click="nextStep" :showActions="false" :showBalance="true" :showCanceledCard="true"/>
    </div>
    <a v-if="idContaCartaoExtrato" @click="()=>{ this.idContaCartaoExtrato = null; }"
      class="expenses__back">
      <v-icon class="expenses__back__icon" name="back" />
      Voltar
    </a>
    <v-extract-table v-if="idContaCartaoExtrato" :extract="extract"/>
  </div>
</template>

<style lang="postcss" scoped>
.container {
  display: inline-block;
  width: auto;
  position: relative;
  padding-left: 40px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 4px;
  left: 5px;
  height: 20px;
  width: 20px;
  background-color: #8bbbd285;
  border-radius: 50%;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: #7dd080;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  top: 7px;
  left: 7px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white;
}
</style>
